import {APIService} from './ApiService';

export const ComputerVisionService = {

  async recognizeCharacters(params) {
    return await APIService.init().
      post('/ocr', params);
  },
  async analyzeImage(params) {
    return await APIService.init().
      post('/describe', params);
  },

  async analyzeFace(params) {
    return await APIService.init().
      post('/face', params);
  },

  async analyzeVideo(params) {
    return await APIService.init().
      post(`/video`, params);
  },
};
