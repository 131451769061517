import Home from '../pages/Home';

import ReceivedCode from '../pages/ReceivedCode';
import VerificationCode from '../pages/VerificationCode';
import NaturalLanguageProcessing from '../pages/NaturalLanguageProcessing';
import Speech from '../pages/Speech';
import ComputerVision from '../pages/ComputerVision';
import Others from '../pages/Others';

export const objRoute = {
  receivedCode: {
    path: '/received-code',
    exact: true,
    component: ReceivedCode,
    requiredLogin: false,
  },
  verificationCode: {
    path: '/verification-code',
    exact: true,
    component: VerificationCode,
    requiredLogin: false,
  },
  home: {
    path: '/',
    exact: true,
    component: Home,
    requiredLogin: false,
  },
  naturalLanguageProcessing: {
    path: '/natural-language-processing',
    exact: true,
    component: NaturalLanguageProcessing,
    requiredLogin: true,
  },
  computerVision: {
    path: '/computer-vision',
    exact: true,
    component: ComputerVision,
    requiredLogin: true,
  },
  others: {
    path: '/others',
    exact: true,
    component: Others,
    requiredLogin: true,
  },
  speech: {
    path: '/speech',
    exact: true,
    component: Speech,
    requiredLogin: true,
  },
};

let routes = [];

for (const key in objRoute) {
  routes.push(objRoute[key]);
}

export default routes;
