import {english} from './en';
import {vietnamese} from './vi';
import {japanese} from './ja';

export default {
  english: {
    translation: english,
  },
  vietnamese: {
    translation: vietnamese,
  },
  japanese: {
    translation: japanese,
  },
};