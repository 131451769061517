import React, {useState} from 'react';
import {Form, Input, Select} from 'antd';
import {CONFIG} from '../../config';
import styles from './styles.module.scss';
import {NaturalLanguageProcessingService} from '../../services/NaturalLanguageProcessing';
import {useTranslation} from 'react-i18next';
import {util} from '../../helpers/util';

export default function TextAnalytics() {
  const [form] = Form.useForm();
  const [result, setResult] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const {t} = useTranslation();
  const onFinish = async (values) => {
    setIsDisabled(true);
    try {
      let response = await NaturalLanguageProcessingService.analyzeSentiment(
        values);
      let result = response.data;
      let data = result.documents.filter(item => item.id === '1');
      if (data.length) {
        setResult(data[0].sentences[0].sentiment);
      }
      if (result.errors.length) {
        let dataError = result.errors.filter(item => item.id === '1');
        if (dataError.length) {
          setResult(dataError[0].message);
        }
      }
      setIsDisabled(false);
    } catch (e) {
      setIsDisabled(false);
      util.handleError(e);
    }

  };

  return (
    <div>
      <Form
        initialValues={{
          inputLanguage: 'en',
        }}
        form={form}
        name="form-text-analytics"
        onFinish={onFinish}
      >
        <div>
          <div className={'font-bold font-16px mb-2'}>{t(
            `Enter the text you’d like to analyze:`)}
          </div>
          <Form.Item
            name={'inputText'}
            rules={[
              {
                required: true,
                whitespace: true,
                message: t(CONFIG.messageValidForm.required),
              }]}
          >
            <Input.TextArea rows={4}/>
          </Form.Item>
        </div>
        <div className={'mt-12 mb-12'}>
          <div className={'font-bold font-16px mb-2'}>{t(
            `Select input language:`)}
          </div>
          <Form.Item
            name={'inputLanguage'}
          >
            <Select>
              {CONFIG.analyticLanguages.map(item =>
                <Select.Option value={item.key}
                               key={item.key}>{item.text}</Select.Option>,
              )}
            </Select>
          </Form.Item>
        </div>
        <button className={'btn-run'} disabled={isDisabled}>
          {t('Run sentiment analysis')}
        </button>
        {result && <div className={styles.result + ' mt-6'}>
          {t(
            `Sentiment scores are provided on a 1 point scale. The closer the sentiment score is to 1, indicates positive sentiment. The closer it is to 0, indicates negative sentiment.`)}
          <br/>
          <strong>{t('Sentiment score for input')}: {result} </strong>
        </div>
        }
      </Form>
    </div>
  );
}
