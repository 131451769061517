import React from 'react';
import styles from '../NaturalLanguageProcessing/styles.module.scss';
import BlockFeature from '../../components/BlockFeature';
import ImageTextToSpeech from '../../assets/imgs/img_text_to_speech.jfif';
import ImageSpeechToText from '../../assets/imgs/img_speech_to_text.jfif';

import MainLayout from '../../layouts/Main';
import TextToSpeech from '../../components/TextToSpeech';
import SpeechToText from '../../components/SpeechToText';
import {CONFIG} from '../../config';
import {useTranslation} from 'react-i18next';

export default function Speech() {
  const {t} = useTranslation();
  return (
    <MainLayout>
      <div className={styles.title + ' text-center lg:my-10 my-8'}>
        {t('Speech')}
      </div>
      <BlockFeature
        backgroundColor='rgb(240 243 249)'
        title={t('Text to Speech (TTS)')}
        description={t(CONFIG.textDescriptionFeature)}
        pathImage={ImageTextToSpeech}
        componentRender={<TextToSpeech/>}
      />
      <BlockFeature
        title={t('Speech to Text (STT)')}
        description={t(CONFIG.textDescriptionFeature)}
        pathImage={ImageSpeechToText}
        isMediaLeft={false}
        componentRender={<SpeechToText/>}
      />

    </MainLayout>
  );
}
