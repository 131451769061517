import React, {useState} from 'react';

import CustomUploadDragger from '../Upload';
import {ComputerVisionService} from '../../services/ComputerVision';
import {message} from 'antd';
import {CONFIG} from '../../config';
import {useTranslation} from 'react-i18next';
import {util} from '../../helpers/util';

export default function FaceDetection(props) {

  const [file, setFile] = useState(null);
  const [result, setResult] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const {t} = useTranslation();

  const getFile = (file) => {
    setFile(file);
  };

  const sendCommand = async () => {
    if (file) {
      setIsDisabled(true);
      try {
        let formData = new FormData();
        formData.append('face', file.originFileObj);
        let res = await ComputerVisionService.analyzeFace(formData);
        if (res.data) {
          setFile({
            ...file,
            imageSrc: `/${res.data}`,
          });
        }
        setIsDisabled(false);
      } catch (e) {
        setIsDisabled(false);
        util.handleError(e);
      }
    } else {
      message.info(t(CONFIG.messageMustSelectFile));
    }
  };

  return (
    <div>
      <CustomUploadDragger
        configUpload={{
          accept: 'image/*',
        }}
        getFile={getFile}
      />
      {file && <img src={file.imageSrc || file.imageBase64}
                    className={'mt-3 max-w-full'}/>}
      <button disabled={isDisabled} className={'btn-run mt-6'}
              onClick={sendCommand}>{t('Detect!')}
      </button>

    </div>
  );
}
