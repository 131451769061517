import React from 'react';

export default function LanguageUnderstanding() {

  const redirectPage = () => {
    window.open('/bot');
  };
  return (
    <div className={'mb-5'}>
      <a className={'font-16px'} onClick={() => redirectPage()}>Restaurant
        Chatbot</a>
    </div>
  );
}
