import React from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';

export default function MainLayout(props) {
  return (
    <>
      <Header/>
      {props.children}
      <Footer
        className={'text-black font-bold py-6 xl:ml-24 mx-5 lg:text-left'}/>
    </>
  );
}
