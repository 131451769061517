export const vietnamese = {
  //========= start header ====================================
  'Home': 'Trang chủ',
  'Computer Vision': 'Computer Vision',
  'Natural Language': 'Ngôn Ngữ Tự Nhiên',
  'Speech': 'Giọng Nói',
  //========= end header ======================================

  //========= start footer =====================================
  'Privacy': 'Quyền riêng tư',
  'Terms': 'Điều khoản',
  //======== end footer ========================================

  //========= start home ========================================
  'Natural Language Processing': 'Xử Lý Ngôn Ngữ Tự Nhiên',
  'Try it out': 'Dùng thử ngay',
  'NTQ is proud to be a company that holds realistic achievement in developing AI for many sectors, and we always support the clients as a reliable partner. To clients who are not specialized in technical domain, we always provide service following the model “offshore research and development + professional expertise” in order to develop customized AI for clients’ business.':
    'NTQ tự hào là công ty có thành tích thực tế trong việc phát triển AI cho nhiều lĩnh vực và chúng tôi luôn hỗ trợ khách hàng như một đối tác đáng tin cậy. Đối với những khách hàng không chuyên về lĩnh vực kỹ thuật, chúng tôi luôn cung cấp dịch vụ theo mô hình “nghiên cứu và phát triển nước ngoài + chuyên môn nghiệp vụ” để phát triển AI tùy chỉnh cho doanh nghiệp của khách hàng.',
  //========= end home ========================================

  //========= start computer vision =============================
  'Analyze Image': 'Phân tích hình ảnh',
  'Analyze Video': 'Phân tích video',
  'Optical Character Recognition (OCR)': 'Nhận dạng ký tự',
  'Get image description': 'Lấy mô tả của ảnh',
  'Face detection': 'Phân tích khuôn mặt',
  'Predict:': 'Dự đoán:',
  'Confident:': 'Độ chính xác:',
  //========= end computer vision ================================

  //========= start natural language processing ==================
  'Text Analytics': 'Phân tích câu',
  'Sentiment scores are provided on a 1 point scale. The closer the sentiment score is to 1, indicates positive sentiment. The closer it is to 0, indicates negative sentiment.': 'Sản phẩm có khả năng đánh giá mức độ tích cực, tiêu cực của 1 đoạn văn bản được nhập vào. Điểm đánh giá từ 0-1, càng gần 1 thì điểm tích cực cao, càng gần 0 thì điểm tiêu cực cao.',
  'Enter the text you’d like to analyze': 'Nhập đoạn văn bạn muốn phân tích:',
  'Enter the text you’d like to translate:': 'Nhập đoạn văn bạn muốn phân tích:',
  'Select voice font:': 'Lựa chọn giọng nói:',
  'Translate to:': 'Dịch với:',
  'Translated text:': 'Câu đã được dịch:',
  'Translate Text': 'Dịch đoạn văn',
  'Convert text-to-speech': 'Chuyển thành giọng nói',
  'Select input language:': 'Chọn ngôn ngữ:',
  'Run sentiment analysis': 'Phân tích câu',
  'Sentiment score for input': 'Kết quả phân tích',
  'Detected language:': 'Ngôn ngữ tìm thấy:',
  'Detection confidence:': 'Kết quả có độ chính xác:',
  'Translator': 'Người phiên dịch',
  'Language Understanding': 'Hiểu ngôn ngữ',
  //========= end natural language processing ==================

  //========= start speech =================================
  'Text to Speech (TTS)': 'Chuyển đoạn văn thành giọng nói',
  'Speech to Text (STT)': 'Chuyển đoạn văn thành giọng nói',
  'Convert speech-to-text': 'Chuyển thành đoạn văn',
  'Text:': 'Đoạn văn:',
  'attach file (.wav)': 'Đính kèm file (.wav)',
  //========= end speech ======================================

  //=============== start received code =======================
  'Fill out the information below to get the code to access the home page.': 'Điền thông tin phía dưới để lấy code truy cập vào trang chủ.',
  'First name': 'Họ',
  'Last name': 'Tên',
  'Send request': 'Gửi yêu cầu',
  'Had a code': 'Đã có mã đăng nhập',
  //============== end received code ==========================

  // ================ start verification code =================
  'Code has been sent to the registration email!': 'Code đã được gửi vào Email đăng ký!',
  'Code': 'Mã',
  'Submit': 'Gửi',
  //================= start verification code =================

  //=================== start text languages===================
  'English': 'Tiếng Anh',
  'Vietnamese': 'Tiếng Việt',
  'Korean': 'Tiếng Hàn Quốc',
  'Japanese': 'Tiếng Nhật Bản',
  'Chinese (Simplified)': 'Tiếng Trung quốc - Giản Thể',
  'English (US)': 'Tiếng Anh (Mỹ)',
  'Chinese': 'Tiếng Trung Quốc',
  'Japanese - Male': 'Tiếng Nhật Bản - Giọng Nam',
  'Japanese - Female': 'Tiếng Nhật Bản - Giọng Nữ',
  //=================== end start text languages==============

  //==================== start common=========================
  'attach image': 'Đính kèm ảnh',
  'attach video': 'Đính kèm video',
  'Click or drag file this area to upload': 'Bấm chọn hoặc kéo thả file vào đây',
  'Detect!': 'Phân tích!',
  'Predict': 'Dự đoán',
  'You must select file': 'Bạn phải chọn file',
  'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry':
    'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry',
  'This field is required': 'Trường này yêu cầu nhập',
  'Wrong code': 'Nhập sai mã',
  'Some thing went wrong': 'Có lỗi xảy ra',
  'This field is not a valid email': 'Nhập không đúng định dạng email',
  //==================== end common=========================
};