import React, {useState} from 'react';
import CustomUploadDragger from '../Upload';
import {ComputerVisionService} from '../../services/ComputerVision';
import {message} from 'antd';
import {CONFIG} from '../../config';
import {useTranslation} from 'react-i18next';
import {util} from '../../helpers/util';

export default function ImageGetDescription(props) {
  const [file, setFile] = useState(null);
  const [result, setResult] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const {t} = useTranslation();

  const getFile = (file) => {
    setFile(file);
  };

  const sendCommand = async () => {
    if (file) {
      setIsDisabled(true);
      try {
        let formData = new FormData();
        formData.append('describe', file.originFileObj);
        let res = await ComputerVisionService.analyzeImage(formData);
        if (res.data && res.data.length) {
          setResult(res.data[0]);
        }
        setIsDisabled(false);
      } catch (e) {
        setIsDisabled(false);
        util.handleError(e);
      }
    } else {
      message.info(t(CONFIG.messageMustSelectFile));
    }
  };

  return (
    <div>
      <CustomUploadDragger
        getFile={getFile}
        configUpload={{
          accept: 'image/*',
        }}
      />
      {file && <img src={file.imageBase64} className={'mt-3 max-w-full'}/>}
      <button disabled={isDisabled} className={'btn-run mt-6'}
              onClick={sendCommand}>{t('Detect!')}
      </button>
      {
        result &&
        <div className={'mt-6'}>
          <div>
            <strong>{t('Predict:')}</strong> {result.text}
          </div>
          <div className={'mt-2'}>
            <strong>{t('Confident:')}</strong> {result.confidence}
          </div>
        </div>
      }
    </div>
  );
}
