import React, {useState} from 'react';
import CustomUploadDragger from '../Upload';
import {Input, message} from 'antd';
import {ComputerVisionService} from '../../services/ComputerVision';
import {CONFIG} from '../../config';
import {useTranslation} from 'react-i18next';
import {util} from '../../helpers/util';

export default function OpticalCharacterRecognition(props) {
  const [file, setFile] = useState(null);
  const [result, setResult] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const {t} = useTranslation();

  const getFile = (file) => {
    setFile(file);
  };

  const sendCommand = async () => {
    if (file) {
      setIsDisabled(true);
      try {
        let formData = new FormData();
        formData.append('ocr', file.originFileObj);
        let res = await ComputerVisionService.recognizeCharacters(formData);
        setResult(res.data || '');
        setIsDisabled(false);
      } catch (e) {
        setIsDisabled(false);
        util.handleError(e);
      }
    } else {
      message.info(t(CONFIG.messageMustSelectFile));
    }
  };

  return (
    <div>
      <CustomUploadDragger
        getFile={getFile}
        configUpload={{
          accept: 'image/*',
        }}
      />
      {file && <img src={file.imageBase64} className={'mt-3 '}/>}
      <button disabled={isDisabled} className={'btn-run mt-6'}
              onClick={sendCommand}>{t('Detect!')}
      </button>
      {
        result && <Input.TextArea rows={6} value={result} className={'mt-6'}/>
      }
    </div>
  );
}
