import {APIService} from './ApiService';

export const RegisterService = {

  async requestForm(params) {
    return await APIService.init().post('/request_form_api', params);
  },

  async verificationCode(params) {
    return await APIService.init().post('/active_form_api', params);
  },

};
