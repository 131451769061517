import axios from 'axios';
import {message} from 'antd';
import {CONFIG} from '../config';

export const APIService = {
  init: (config) => {
    const instanceAxios = axios.create({
      timeout: 150000,
      // baseURL: 'http://13.78.81.137:5100/',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      ...config,
    });

    instanceAxios.interceptors.response.use(function(response) {
      return response;
    }, function(e) {
      let dataError = {...e};
      if (!e.request.status) {
        message.error(CONFIG.networkOrTimoutError);
        dataError.isShowError = true;
      }
      return Promise.reject(dataError);
    });
    return instanceAxios;
  },

};
