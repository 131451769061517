import React from 'react';
import {Upload} from 'antd';
import styles from './styles.module.scss';
import {util} from '../../helpers/util';
import {useTranslation} from 'react-i18next';

const {Dragger} = Upload;

export default function CustomUploadDragger(props) {
  const {t} = useTranslation();
  let configUpload = props.configUpload || {};
  let buttonUploadName = props.buttonUploadName || t('attach image');
  const config = {
    name: 'file',
    multiple: false,
    maxCount: 1,
    customRequest({file, onSuccess}) {
      onSuccess('ok');
    },
    onChange(info) {
      util.getBase64(info.file.originFileObj, imageUrl => {
          let file = null;
          if (info.fileList.length) {
            file = {
              imageBase64: imageUrl,
              ...info.file,
            };
          }
          props.getFile && props.getFile(file);
        },
      );
    },
    ...configUpload,
  };

  return (
    <Dragger {...config}>
      <p className="ant-upload-drag-icon">
        <button className={styles.btnChooseFile +
        ' uppercase mb-0'}>{buttonUploadName}
        </button>
      </p>
      <p className={styles.descriptionUpload}>{t(
        'Click or drag file this area to upload')}</p>
    </Dragger>
  );
}
