import {APIService} from './ApiService';

export const SpeechService = {
  configurationAxios: {
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: 'blob',
  },

  async textToSpeech(params) {
    return await APIService.init({
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
    }).
      post('/text-to-speech', params);
  },

  async speechToText(params) {
    return await APIService.init().post('/2', params);
  },
};
