import React, {useEffect, useRef, useState} from 'react';
import {Form, Input, Select} from 'antd';
import {CONFIG} from '../../config';
import {SpeechService} from '../../services/Speech';
import {useTranslation} from 'react-i18next';
import {util} from '../../helpers/util';

export default function TextToSpeech(props) {
  const [form] = Form.useForm();

  const [isDisabled, setIsDisabled] = useState(false);
  const [sourceAudio, setSourceAudio] = useState('');
  const audioRef = useRef(null);
  const {t} = useTranslation();

  const convertToSpeech = async (values) => {
    setIsDisabled(true);
    try {
      let res = await SpeechService.textToSpeech({
        text: values.text,
        voice: values.languageToSpeech,
      });
      let audioBlob = new Blob([res.data], {type: 'audio/mpeg'});
      let audioURL = URL.createObjectURL(audioBlob);
      if (audioURL.length > 5) {
        setSourceAudio(audioURL);
      }
      setIsDisabled(false);
    } catch (e) {
      setIsDisabled(false);
      util.handleError(e);
    }

  };

  useEffect(() => {
    if (sourceAudio) {
      audioRef.current.load();
      audioRef.current.play();
    }
  }, [sourceAudio]);

  return (
    <Form
      initialValues={{
        languageToSpeech: '(en-US, AriaRUS)',
      }}
      form={form}
      name="form-text-analytics"
      onFinish={convertToSpeech}
    >
      <div className=''>
        <div className={'font-bold font-16px mb-2'}>{t('Text:')}</div>
        <Form.Item
          name={'text'}
          rules={[
            {
              required: true,
              whitespace: true,
              message: t(CONFIG.messageValidForm.required),
            }]}
        >
          <Input.TextArea rows={4}/>
        </Form.Item>
      </div>
      <div className={'mt-12 mb-12'}>
        <div className={'font-bold font-16px mb-2'}>{t(
          'Select voice font:')}</div>
        <Form.Item
          name={'languageToSpeech'}
        >
          <Select>
            {CONFIG.textToSpeechLanguages.map(item =>
              <Select.Option value={item.key}
                             key={item.key}>{item.text}</Select.Option>,
            )}
          </Select>
        </Form.Item>
      </div>
      <button className={'btn-run'}
              disabled={isDisabled}
      >{t('Convert text-to-speech')}
      </button>
      {sourceAudio &&
      <audio controls className={'mt-3 md:w-8/12 w-11-12 '} ref={audioRef}>
        <source src={sourceAudio} type="audio/mpeg"/>
        Your browser does not support the audio element.
      </audio>
      }
    </Form>
  );
}
