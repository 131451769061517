import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import styles from '../NaturalLanguageProcessing/styles.module.scss';
import BlockFeature from '../../components/BlockFeature';
import ImageAnalyze from '../../assets/imgs/img_analytic_image.jfif';
import ImageRecognition from '../../assets/imgs/img_recognition.jfif';
import Video from '../../assets/video/result.webm';

import MainLayout from '../../layouts/Main';
import AnalyticImage from '../../components/AnalyticImage';
import AnalyticVideo from '../../components/AnalyticVideo';
import OpticalCharacterRecognition
  from '../../components/OpticalCharacterRecognition';
import {CONFIG} from '../../config';

export default function ComputerVision() {
  const [videoPathResponse, setVideoPathResponse] = useState(null);
  const {t} = useTranslation();

  const updateVideoPath = (path) => {
    let date = new Date();
    setVideoPathResponse(`${path}?timestamp=${date.getTime()}`);
  };

  return (
    <MainLayout>
      <div className={styles.title + ' text-center lg:my-10 my-8'}>
        {t('Computer Vision')}
      </div>
      <BlockFeature
        backgroundColor='rgb(240 243 249)'
        title={t('Analyze Image')}
        description={t(CONFIG.textDescriptionFeature)}
        pathImage={ImageAnalyze}
        componentRender={<AnalyticImage/>}
      />
      <BlockFeature
        title={t('Analyze Video')}
        description={t(CONFIG.textDescriptionFeature)}
        isMediaLeft={false}
        videoPath={videoPathResponse || Video}
        componentRender={
          <AnalyticVideo
            callbackUpdateVideoPath={(path) => updateVideoPath(path)}/>
        }
      />
      <BlockFeature
        backgroundColor='rgb(240 243 249)'
        title={t('Optical Character Recognition (OCR)')}
        description={t(CONFIG.textDescriptionFeature)}
        pathImage={ImageRecognition}
        componentRender={<OpticalCharacterRecognition/>}
      />
    </MainLayout>
  );
}
