import React, {useEffect, useState} from 'react';
import {Route} from 'react-router-dom';
import {Spin} from 'antd';
import {APIService} from '../../services/ApiService';
import {objRoute} from '../../routes';

const EmptyLayout = (props) => {
  return props.children;
};

function AppRoute(props) {
  const {component: Component, requiredLogin, ...rest} = props;
  const [checkedPermission, setCheckedPermission] = useState(false);
  const Layout = props.layout || EmptyLayout;

  useEffect(() => {
    const validatedToken = localStorage.getItem('validatedToken');

    if (!requiredLogin || validatedToken) {
      setCheckedPermission(true);
    } else {
      APIService.init({
        headers: {'Content-Type': 'application/json'},
      }).get('/valid').then((res) => {
        const isValid = res.data.valid;
        if (!isValid) {
          window.location.href = objRoute.receivedCode.path;
        } else {
          localStorage.setItem('validatedToken', '1');
          setCheckedPermission(true);
        }
      }).catch(err => {
        console.error(err);
      });
    }
  }, []);

  if (!checkedPermission) {
    return (
      <div className="checking-permission">
        <Spin/>
      </div>
    );
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        <Layout>
          <Component {...props} />
        </Layout>
      }
    />
  );
}

export default AppRoute;
