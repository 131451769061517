import {APIService} from './ApiService';

export const NaturalLanguageProcessingService = {

  configurationAxios: {
    headers: {'Content-Type': 'application/json'},
  },

  async analyzeSentiment(params) {
    return await APIService.init(
      NaturalLanguageProcessingService.configurationAxios).
      post('/sentiment-analysis', params);
  },

  async translate(params) {
    return await APIService.init(
      NaturalLanguageProcessingService.configurationAxios).
      post('/translate-text', params);
  },

  async textToSpeech(params) {
    return await APIService.init({
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
    }).post('/text-to-speech', params);
  },

};
