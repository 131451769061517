import React, {useState} from 'react';
import CustomUploadDragger from '../Upload';
import {ComputerVisionService} from '../../services/ComputerVision';
import {message} from 'antd';
import {CONFIG} from '../../config';
import {useTranslation} from 'react-i18next';
import {util} from '../../helpers/util';

export default function AnalyticVideo(props) {
  const [file, setFile] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const {t} = useTranslation();

  const getFile = (file) => {
    setFile(file);
  };

  const sendCommand = async () => {
    if (file) {
      setIsDisabled(true);
      try {
        let formData = new FormData();
        formData.append('video', file.originFileObj);
        let res = await ComputerVisionService.analyzeVideo(formData);
        props.callbackUpdateVideoPath && props.callbackUpdateVideoPath(
          `/${res.data}`);
        setIsDisabled(false);
      } catch (e) {
        setIsDisabled(false);
        util.handleError(e);
      }
    } else {
      message.info(t(CONFIG.messageMustSelectFile));
    }
  };
  return (
    <div>
      <CustomUploadDragger
        buttonUploadName={t('attach video')}
        configUpload={{
          accept: 'video/mp4,video/x-m4v,video/*',
        }}
        getFile={getFile}/>
      <button disabled={isDisabled} className={'btn-run mt-6'}
              onClick={sendCommand}>{t('Detect!')}
      </button>
    </div>
  );
}
