import React from 'react';
import {Collapse} from 'antd';
import FaceDetection from './FaceDetection';
import ImageGetDescription from './ImageGetDescription';
import {useTranslation} from 'react-i18next';

const {Panel} = Collapse;

export default function AnalyticImage(props) {
  const {t} = useTranslation();
  return (
    <div>
      <Collapse accordion>
        <Panel header={t('Get image description')} key="1">
          <ImageGetDescription/>
        </Panel>
        <Panel header={t('Face detection')} key="2">
          <FaceDetection/>
        </Panel>
      </Collapse>

    </div>
  );
}
