import React, {useState} from 'react';
import CustomUploadDragger from '../Upload';
import {Input, message} from 'antd';
import {CONFIG} from '../../config';
import {SpeechService} from '../../services/Speech';
import {useTranslation} from 'react-i18next';
import {util} from '../../helpers/util';

export default function SpeechToText() {

  const [file, setFile] = useState(null);
  const [result, setResult] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const {t} = useTranslation();
  const getFile = (file) => {
    setFile(file);
  };
  const sendCommand = async () => {
    if (file) {
      setIsDisabled(true);
      try {
        let formData = new FormData();
        formData.append('stt', file.originFileObj);
        let res = await SpeechService.speechToText(formData);
        let result = res.data || '{}';
        setResult(JSON.parse(result));
        setIsDisabled(false);
      } catch (e) {
        setIsDisabled(false);
        util.handleError(e);
      }
    } else {
      message.info(t(CONFIG.messageMustSelectFile));
    }
  };

  return (
    <div>
      <CustomUploadDragger
        configUpload={{
          accept: 'audio/wav',
        }}
        buttonUploadName={t('attach file (.wav)')}
        getFile={getFile}
      />
      <button disabled={isDisabled} className={'btn-run mt-6'}
              onClick={sendCommand}>{t('Convert speech-to-text')}
      </button>
      {
        result && <Input.TextArea rows={6} value={result.DisplayText || ''}
                                  className={'mt-6'}/>
      }
    </div>
  );
}
