import React, {useState} from 'react';
import CustomUploadDragger from '../Upload';
import {message} from 'antd';
import {CONFIG} from '../../config';
import {useTranslation} from 'react-i18next';
import {util} from '../../helpers/util';
import {OtherService} from '../../services/OtherService';

export default function OCRForIdCard(props) {
  const [file, setFile] = useState(null);
  const [result, setResult] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const {t} = useTranslation();

  const getFile = (file) => {
    setResult(null);
    setFile(file);
  };

  const sendCommand = async () => {
    if (file) {
      setIsDisabled(true);
      try {
        let formData = new FormData();
        formData.append('image', file.originFileObj);
        formData.append('template', 0); // TBD define 0 and 1

        let res = await OtherService.analyzeOther(formData);
        if (res.data) {
          setResult(res.data);
        }
        setIsDisabled(false);
      } catch (e) {
        setIsDisabled(false);
        util.handleError(e);
      }
    } else {
      message.info(t(CONFIG.messageMustSelectFile));
    }
  };

  return (
    <div>
      <CustomUploadDragger
        getFile={getFile}
        configUpload={{
          accept: 'image/*',
        }}
      />
      {file && <img src={file.imageBase64} className={'mt-3 max-w-full'}/>}
      <button disabled={isDisabled} className={'btn-run mt-6'}
              onClick={sendCommand}>{t('Detect!')}
      </button>
      {
        result && (typeof result === 'string' ?
            <div className={'mt-6'}>
              <strong>{result}</strong>
            </div>
            :
            <div className={'mt-6'}>
              {
                result.id && <div className={'mt-2'}>
                  <strong>{t('ID:')}</strong> {result.id}
                </div>
              }
              {
                result.name && <div className={'mt-2'}>
                  <strong>{t('Name:')}</strong> {result.name}
                </div>
              }
              {
                result.gender && <div className={'mt-2'}>
                  <strong>{t('Gender:')}</strong> {result.gender}
                </div>
              }
              {
                result.dob &&
                <div className={'mt-2'} className={'mt-2'}>
                  <strong>{t('Date of birth:')}</strong> {result.dob}
                </div>
              }
              {
                result.addr && <div className={'mt-2'}>
                  <strong>{t('Living address:')}</strong> {result.addr}
                </div>
              }
              {
                result.hktt && <div className={'mt-2'}>
                  <strong>{t('Permanent residence:')}</strong> {result.hktt}
                </div>
              }
              {
                result.expire && <div className={'mt-2'}>
                  <strong>{t('Expire:')}</strong> {result.expire}
                </div>
              }
            </div>
        )
      }
    </div>
  );
}
