import React, {useEffect, useState} from 'react';
import {Form, Input, message, Select} from 'antd';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import styles from './styles.module.scss';
import Footer from '../../components/Footer';
import {CONFIG} from '../../config';
import {objRoute} from '../../routes';
import {RegisterService} from '../../services/RegisterService';
import {util} from '../../helpers/util';

export default function VerificationCode() {
  const [form] = Form.useForm();
  const history = useHistory();
  const [isDisabled, setIsDisabled] = useState(false);
  const [locale, setLocale] = useState('english');
  const {t, i18n} = useTranslation();

  const onFinish = async (values) => {
    setIsDisabled(true);
    try {
      let bodyFormData = new FormData();
      bodyFormData.append('active_code', values.code.trim());
      setIsDisabled(false);
      const result = await RegisterService.verificationCode(bodyFormData);
      const isSuccess = result.data.status;
      if (isSuccess) {
        history.push(objRoute.home.path);
      } else {
        setIsDisabled(false);
        message.error(t(CONFIG.wrongCode));
      }

    } catch (e) {
      setIsDisabled(false);
      util.handleError(e);
    }
  };

  const changeLanguage = (value) => {
    i18n.changeLanguage(value);
    setLocale(value);
    localStorage.setItem(CONFIG.languageKey, value);
  };

  useEffect(() => {
    if (localStorage.getItem(CONFIG.languageKey)) {
      setLocale(localStorage.getItem(CONFIG.languageKey));
    }
  }, []);

  return (
    <div className={styles.bgVerification + ' h-full min-h-screen'}>
      <div className='container-login md:w-6/12 w-10/12 mx-auto'>
        <div className={styles.title + ' text-white pt-40 text-center'}>
          NTQ AI Demo Website
        </div>
        <div className={styles.description + ' mt-5 text-center'}>
          {t('Code has been sent to the registration email!')}
        </div>
        <Form
          className='mt-12 xl:w-6/12 w-10/12 mx-auto'
          form={form}
          onFinish={onFinish}
        >

          <div className={styles.itemForm + ' mx-auto mt-4'}>
            <div className={styles.nameField + ' text-white text-left mb-3'}>
              {t('Code')}
            </div>
            <Form.Item
              name="code"
              rules={[
                {
                  whitespace: true,
                  required: true,
                  message: t(CONFIG.messageValidForm.required),
                },
              ]}
            >
              <Input className='w-full'
                     type='text'/>
            </Form.Item>
          </div>
          <div className={'text-center'}>
            <button className="btn-submit-form lg:mt-12 mt-3"
                    disabled={isDisabled}>
              {t('Submit')}
            </button>
          </div>
          <div className='mt-6 text-center'>
            <Footer/>
          </div>
          <div className={'flex pb-24 justify-center items-center mt-10'}>
            <div className={'text-white mr-6'}>{t(
              'Select input language:')}</div>
            <Select value={locale}
                    onChange={changeLanguage}>
              <Select.Option value="english">{'English'}</Select.Option>
              <Select.Option value="japanese">{'日本語'}</Select.Option>
            </Select>
          </div>
        </Form>
      </div>
    </div>
  );
}
