import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Dropdown, Menu} from 'antd';
import {DownOutlined} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';

import Logo from '../../assets/imgs/logo.png';
import Global from '../../assets/imgs/global.png';
import styles from './styles.module.scss';
import {objRoute} from '../../routes';
import {CONFIG} from '../../config';
import {util} from '../../helpers/util';

export default function Header() {
  const {t, i18n} = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const history = useHistory();
  const [currentLanguage, setCurrentLanguage] = useState('English');

  const changeLanguage = (data) => {
    let language = data.key;
    i18n.changeLanguage(language.toLowerCase());
    localStorage.setItem(CONFIG.languageKey, language.toLowerCase());
    setCurrentLanguage(language);
  };

  const menu = (
    <Menu onClick={changeLanguage} selectedKeys={[currentLanguage]}>
      <Menu.Item key="English">
        <a>
          English
        </a>
      </Menu.Item>
      <Menu.Item key="Japanese">
        <a>
          日本語
        </a>
      </Menu.Item>
    </Menu>
  );

  const redirectPage = (path) => {
    history.push(path);
  };

  const checkActiveMenu = (path) => {
    if (path === objRoute.home.path) {
      return history.location.pathname === objRoute.home.path;
    } else {
      return history.location.pathname.includes(path);
    }
  };

  useEffect(() => {
    if (localStorage.getItem(CONFIG.languageKey)) {
      let language = util.capitalize(localStorage.getItem(CONFIG.languageKey));
      setCurrentLanguage(language);
    }
  }, []);

  return (
    <div className="flex flex-wrap py-2 shadow-lg">
      <div className="w-full">
        <nav
          className="relative flex flex-wrap items-center justify-between py-6 bg-blueGray-500 rounded">
          <div
            className="flex w-full flex-wrap items-center justify-between">
            <div
              className="w-full lg:w-auto px-5 xl:px-0 xl:ml-24 lg:absolute flex justify-between lg:justify-start">
              <img onClick={() => redirectPage('/')} src={Logo}
                   className={styles.logo + ' cursor-pointer'}/>

              <button
                className="border-1px-primary color-text-primary cursor-pointer text-xl leading-none px-3 py-1 rounded bg-transparent block lg:hidden outline-none focus:outline-none"
                type="button"
                onClick={() => setMenuOpen(!menuOpen)}
              >
                ☰
              </button>
            </div>
            <div
              className={
                'lg:flex flex-grow items-center justify-center' +
                (menuOpen ? ' flex' : ' hidden')
              }
              id="example-navbar-info"
            >
              <ul
                className="w-full lg:w-auto px-5 lg:px-0 flex mt-4 lg:mt-0 flex-col lg:flex-row list-none">
                <li className="nav-item mr-6">
                  <a
                    className={'text-menu px-3 py-2 flex items-center leading-snug text-black hover:opacity-75' +
                    (checkActiveMenu(objRoute.home.path)
                      ? ' text-menu-active'
                      : '')}
                    onClick={() => redirectPage(objRoute.home.path)}
                  >
                    {t('Home')}
                  </a>
                </li>
                <li className="nav-item mr-6">
                  <a
                    className={'text-menu px-3 py-2 flex items-center leading-snug text-black hover:opacity-75' +
                    (checkActiveMenu(objRoute.computerVision.path)
                      ? ' text-menu-active'
                      : '')}
                    onClick={() => redirectPage(objRoute.computerVision.path)}
                  >
                    {t('Computer Vision')}
                  </a>
                </li>
                <li className="nav-item mr-6">
                  <a
                    className={'text-menu px-3 py-2 flex items-center leading-snug text-black hover:opacity-75' +
                    (checkActiveMenu(objRoute.naturalLanguageProcessing.path)
                      ? ' text-menu-active'
                      : '')}
                    onClick={() => redirectPage(
                      objRoute.naturalLanguageProcessing.path)}
                  >
                    {t('Natural Language')}
                  </a>
                </li>
                <li className="nav-item mr-6">
                  <a
                    className={'text-menu px-3 py-2 flex items-center leading-snug text-black hover:opacity-75' +
                    (checkActiveMenu(objRoute.speech.path)
                      ? ' text-menu-active'
                      : '')}
                    onClick={() => redirectPage(objRoute.speech.path)}
                  >
                    {t('Speech')}
                  </a>
                </li>
                <li className="nav-item mr-6">
                  <a
                    className={'text-menu px-3 py-2 flex items-center leading-snug text-black hover:opacity-75' +
                    (checkActiveMenu(objRoute.others.path)
                      ? ' text-menu-active'
                      : '')}
                    onClick={() => redirectPage(objRoute.others.path)}
                  >
                    {t('Others')}
                  </a>
                </li>
                <li className="nav-item flex items-center ">
                  <img src={Global} width={16} height={16} className={'ml-3'}/>
                  <Dropdown overlay={menu}>
                    <a
                      className="text-menu px-2 py-2 ant-dropdown-link text-black"
                      onClick={e => e.preventDefault()}>
                      {t(currentLanguage)} <DownOutlined/>
                    </a>
                  </Dropdown>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}
