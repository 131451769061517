import React, {useEffect} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import AppRouter from './components/AppRouter';
import {useTranslation} from 'react-i18next';
import {CONFIG} from './config';

function App() {
  const {i18n} = useTranslation();
  useEffect(() => {
    if (localStorage.getItem(CONFIG.languageKey)) {
      i18n.changeLanguage(localStorage.getItem(CONFIG.languageKey));
    }
  }, []);
  return (
    <Router>
      <AppRouter/>
    </Router>
  );
}

export default App;
