export const japanese = {
  //========= start header ====================================
  'Home': 'ホーム',
  'Computer Vision': 'コンピュータビジョン',
  'Natural Language': '自然言語',
  'Speech': 'スピーチ',
  'Others': 'その他',
  //========= end header ======================================

  //========= start footer =====================================
  'Privacy': 'プライバシー',
  'Terms': '規約',
  //======== end footer ========================================

  //========= start home ========================================
  'Natural Language Processing': '自然言語処理',
  'Try it out': '試してください',
  'NTQ is proud to be a company that holds realistic achievement in developing AI for many sectors, and we always support the clients as a reliable partner. To clients who are not specialized in technical domain, we always provide service following the model “offshore research and development + professional expertise” in order to develop customized AI for clients’ business.':
    'NTQは、多くの分野でAIの開発において現実的な成果を上げている企業であることを誇りに思っており、信頼できるパートナーとして常にクライアントをサポートしています。 技術分野に特化していないお客様には、お客様のビジネスに合わせてカスタマイズされたAIを開発するために、「オフショア研究開発と専門知識」というモデルに沿ったサービスを常に提供しています。',
  //========= end home ========================================

  //========= start computer vision =============================
  'Analyze Image': '画像を解析する',
  'Analyze Video': '動画を解析する',
  'Optical Character Recognition (OCR)': '光学文字認識',
  'Get image description': '画像の説明を取得する',
  'Face detection': '顔認識',
  'Confident:': '自信:',
  //========= end computer vision ================================

  //========= start natural language processing ==================
  'Text Analytics': 'テキスト分析',
  'Sentiment scores are provided on a 1 point scale. The closer the sentiment score is to 1, indicates positive sentiment. The closer it is to 0, indicates negative sentiment.':
    'Sentiment scores are provided on a 1 point scale. The closer the sentiment score is to 1, indicates positive sentiment. The closer it is to 0, indicates negative sentiment.',
  'Enter the text you’d like to analyze:': '分析したいテキストを入力してください:',
  'Enter the text you’d like to translate:': '翻訳したいテキストを入力してください:',
  'Select voice font:': '声のフォント選択：',
  'Translate to:': '翻訳:',
  'Translated text:': '翻訳されたテキスト：',
  'Translate Text': 'テキストを翻訳する',
  'Convert text-to-speech': 'テキストをスピーチに変換する',
  'Select input language:': '入力言語を選択ください:',
  'Run sentiment analysis': '感情分析を実行する',
  'Sentiment score for input': 'インプットの感情スコア',
  'Detected language:': '言語を検出しました:',
  'Detection confidence:': '自信検出:',
  'Translator': '翻訳者',
  'Language Understanding': '言語の理解',
  //========= end natural language processing ==================

  //========= start speech =================================
  'Text to Speech (TTS)': 'テキストトゥスピーチ',
  'Speech to Text (STT)': 'スピーチトゥテキスト',
  'Convert speech-to-text': 'スピーチをテキストに変換する',
  'Text:': 'テキスト:',
  'attach file (.wav)': 'ファイル（.wav）を添付する',
  //========= end speech ======================================

  //=============== start received code =======================
  'Fill out the information below to get the code to access the home page.': 'ホームページにアクセスするためのコードを取得するには、以下の情報を入力してください',
  'First name': '名',
  'Last name': '姓',
  'Send request': '申請',
  'E-mail': '電子メール',
  'Had a code': '既にコードがあります',
  //============== end received code ==========================

  // ================ start verification code =================
  'Code has been sent to the registration email!': 'コードは登録メールへ送信されました',
  'Code': 'コード',
  'Submit': '送信',
  //================= start verification code =================

  //=================== start text languages===================
  'English': '英語',
  'Vietnamese': 'ベトナム語',
  'Korean': '韓国語',
  'Japanese': '日本語',
  'Chinese (Simplified)': 'Tiếng Trung quốc - Giản Thể',
  'English (US)': '英語（米国)',
  'Chinese': '中国語',
  'Japanese - Male': 'Tiếng Nhật Bản - Giọng Nam',
  'Japanese - Female': 'Tiếng Nhật Bản - Giọng Nữ',
  //=================== end text languages==============

  //=================== start OCR for ID card=============

  'Name:': '名前:',
  'Permanent residence:': '永住権住所:',
  'Date of birth:': '生年月日:',
  'Living address:': '住所:',
  'Optical Character Recognition(OCR) for ID card': 'IDカードの光学式文字認識（OCR)',
  'Gender:': '性別:',
  'Expire:': '有効期限:',
  'Extract information from Vietnamese identification cards using Optical Character Recognition (OCR) technique'
    :'',
  //=================== end  OCR for ID card==============

  //==================== start common=========================
  'attach image': '画像を添付する',
  'attach video': '動画を添付する',
  'Click or drag file this area to upload': 'ここにファイルをドロップ、またはファイルを選択してください',
  'Detect!': '見つかりました',
  'Predict:': '予測:',
  'You must select file': 'ファイルを選択してください',
  'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry':
    '',
  'This field is required': 'この項目は必須です',
  'Wrong code': 'コードが間違っています',
  'Some thing went wrong': '何らかの問題が発生しました',
  'This field is not a valid email': 'この項目は有効なメールアドレスではありません',
  //==================== end common=========================
};