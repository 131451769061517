import React from 'react';
import {Switch} from 'react-router-dom';
import routes from '../../routes';
import AppRoute from '../../components/AppRoute';

function AppRouter() {
  return (
    <Switch>
      {routes.map((item) => {
        return (
          <AppRoute
            key={item.path}
            exact={item.exact}
            path={item.path}
            component={item.component}
            layout={item.layout}
            requiredLogin={item.requiredLogin}
          />
        );
      })}
    </Switch>
  );
}

export default AppRouter;
