import React from 'react';
import styles from './styles.module.scss';
import {useTranslation} from 'react-i18next';

export default function Footer(props) {
  const {t} = useTranslation();

  const className = props.className || '';
  return (
    <footer className={styles.attribute + ' ' + className}>
      © 2017-2021 NTQ Solution JSC &nbsp; · <span
      className={styles.textPrimary + ' cursor-pointer'}>{t('Privacy')} </span>
      · <span className={styles.textPrimary + ' cursor-pointer'}>{t(
      'Terms')}</span>
    </footer>
  );
}
