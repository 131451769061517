import {Form, Input, message, Select} from 'antd';
import React, {useEffect, useState} from 'react';
import Footer from '../../components/Footer';
import styles from './styles.module.scss';
import {useHistory} from 'react-router-dom';
import {objRoute} from './../../routes/index';
import {CONFIG} from '../../config';
import {RegisterService} from '../../services/RegisterService';
import {useTranslation} from 'react-i18next';
import {util} from '../../helpers/util';

export default function ReceivedCode() {
  const [form] = Form.useForm();
  const history = useHistory();
  const [isDisabled, setIsDisabled] = useState(false);
  const [locale, setLocale] = useState('english');
  const {t, i18n} = useTranslation();

  const onFinish = async (values) => {
    setIsDisabled(true);
    try {
      let bodyFormData = new FormData();
      bodyFormData.append('first_name', values.first_name.trim());
      bodyFormData.append('last_name', values.last_name.trim());
      bodyFormData.append('email', values.email.trim());
      const res = await RegisterService.requestForm(bodyFormData);
      const isSuccess = res.data.status;

      if (isSuccess) {
        setIsDisabled(false);
        history.push(objRoute.verificationCode.path);
      } else {
        setIsDisabled(false);
        message.error(CONFIG.error);
      }
    } catch (e) {
      setIsDisabled(false);
      util.handleError(e);
    }
  };

  const redirectVerificationCode = () => {
    history.push(objRoute.verificationCode.path);
  };

  const changeLanguage = (value) => {
    i18n.changeLanguage(value);
    setLocale(value);
    localStorage.setItem(CONFIG.languageKey, value);
  };

  useEffect(() => {
    if (localStorage.getItem(CONFIG.languageKey)) {
      setLocale(localStorage.getItem(CONFIG.languageKey));
    }
  }, []);

  return (
    <div className={styles.bgLogin + ' h-full min-h-screen'}>
      <div className='container-login md:w-6/12 w-10/12 mx-auto'>
        <div className={styles.title + ' text-white pt-24 text-center'}>
          NTQ AI Demo Website
        </div>
        <div className={styles.description + ' mt-5 text-center'}>
          {t(
            'Fill out the information below to get the code to access the home page.')}
        </div>
        <Form
          className='mt-12 xl:w-6/12 w-10/12  mx-auto'
          form={form}
          onFinish={onFinish}
        >
          <div className={styles.itemForm + ' mx-auto'}>
            <div className={styles.nameField + ' text-white text-left mb-3'}>
              {t('First name')}
            </div>
            <Form.Item
              name="first_name"
              rules={[
                {
                  whitespace: true,
                  required: true,
                  message: t(CONFIG.messageValidForm.required),
                },
              ]}
            >
              <Input className='w-full' maxLength={200}
                     type='text'/>
            </Form.Item>
          </div>
          <div className={styles.itemForm + ' mx-auto mt-4'}>
            <div className={styles.nameField + ' text-white text-left mb-3'}>
              {t('Last name')}
            </div>
            <Form.Item
              name="last_name"
              rules={[
                {
                  whitespace: true,
                  required: true,
                  message: t(CONFIG.messageValidForm.required),
                },
              ]}
            >
              <Input className='w-full' maxLength={200}
                     type='text'/>
            </Form.Item>
          </div>
          <div className={styles.itemForm + ' mx-auto mt-4'}>
            <div className={styles.nameField + ' text-white text-left mb-3'}>
              {t('E-mail')}
            </div>
            <Form.Item
              name="email"
              rules={[
                {
                  whitespace: true,
                  required: true,
                  message: t(CONFIG.messageValidForm.required),
                },
                {
                  type: 'email',
                  message: t(CONFIG.messageValidForm.email),
                },
              ]}
            >
              <Input className='w-full' maxLength={200}
                     type='text'/>
            </Form.Item>
            <div className={'mt-12'}>
              <div className={'text-center'}>
                <button className="btn-submit-form" disabled={isDisabled}>
                  {t('Send request')}
                </button>
                <a onClick={() => redirectVerificationCode()}
                   className={styles.hadACode + ' text-white mt-3 block'}>
                  {t('Had a code')}</a>
              </div>

            </div>
          </div>

          <div className='mt-6 text-center'>
            <Footer/>
          </div>
          <div className={'flex pb-24 justify-center items-center mt-10'}>
            <div className={'text-white mr-6'}>{t(
              'Select input language:')}</div>
            <Select value={locale}
                    onChange={changeLanguage}>
              <Select.Option value="english">{'English'}</Select.Option>
              <Select.Option value="japanese">{'日本語'}</Select.Option>
            </Select>
          </div>
        </Form>
      </div>
    </div>
  );
}
