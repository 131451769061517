import React from 'react';

import MainLayout from '../../layouts/Main';
import styles from './styles.module.scss';
import BlockFeature from '../../components/BlockFeature';
import imageTextAnalytic from '../../assets/imgs/img_text_analytics.jfif';
import imageLanguageStanding
  from '../../assets/imgs/img_language_standing.jfif';
import imageTranslator from '../../assets/imgs/img_translator.jfif';
import TextAnalytics from '../../components/TextAnalytics';
import Translator from '../../components/Translator';
import LanguageUnderstanding from '../../components/LanguageUnderstanding';
import {CONFIG} from '../../config';
import {useTranslation} from 'react-i18next';

export default function NaturalLanguageProcessing() {
  const {t} = useTranslation();
  return (
    <MainLayout>
      <div className={styles.title + ' text-center lg:my-10 my-8'}>
        {t('Natural Language Processing')}
      </div>
      <BlockFeature
        backgroundColor='rgb(240 243 249)'
        title={t('Text Analytics')}
        description={t(CONFIG.textDescriptionFeature)}
        pathImage={imageTextAnalytic}
        componentRender={<TextAnalytics/>}
      />
      <BlockFeature
        title={t('Language Understanding')}
        description={t(CONFIG.textDescriptionFeature)}
        isMediaLeft={false}
        pathImage={imageLanguageStanding}
        componentRender={<LanguageUnderstanding/>}
      />
      <BlockFeature
        backgroundColor='rgb(240 243 249)'
        title={t('Translator')}
        description={t(CONFIG.textDescriptionFeature)}
        pathImage={imageTranslator}
        componentRender={<Translator/>}
      />
    </MainLayout>
  );
}
