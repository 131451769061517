import React from 'react';
import {useTranslation} from 'react-i18next';

import styles from '../NaturalLanguageProcessing/styles.module.scss';
import BlockFeature from '../../components/BlockFeature';
import Card from '../../assets/imgs/card.jpg';

import MainLayout from '../../layouts/Main';
import {CONFIG} from '../../config';
import OCRForIdCard
  from '../../components/OpticalCharacterRecognitionForIdCard';

export default function Others() {
  const {t} = useTranslation();
  return (
    <MainLayout>
      <div className={styles.title + ' text-center lg:my-10 my-8'}>
        {t('Others')}
      </div>
      <BlockFeature
        backgroundColor='rgb(240 243 249)'
        title={t('Optical Character Recognition (OCR) for ID card')}
        description={t('Extract information from Vietnamese identification cards using Optical Character Recognition (OCR) technique')}
        pathImage={Card}
        componentRender={<OCRForIdCard/>}
      />
    </MainLayout>
  );
}
