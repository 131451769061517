import {message} from 'antd';
import {CONFIG} from '../config';

export const util = {
  getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  },
  appendValuesFormData(formData, objValue) {
    Object.keys(objValue).map(property => {
      formData.append(property, objValue[property]);
    });
  },
  capitalize(s) {
    return s && s[0].toUpperCase() + s.slice(1);
  },
  handleError(e) {
    if (!e.isShowError) {
      console.error(e);
      message.error(CONFIG.error);
    }
  },
};
