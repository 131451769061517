import React, {useEffect, useRef, useState} from 'react';
import {Col, Row} from 'antd';
import styles from './styles.module.scss';
import {useTranslation} from 'react-i18next';

export default function BlockFeature(props) {

  let backgroundColor = props.backgroundColor || 'white';
  let isMediaLeft = props.isMediaLeft ?? true;
  let pathImage = props.pathImage || '';
  let title = props.title || '';
  let description = props.description || '';
  let componentRender = props.componentRender;
  let videoPath = props.videoPath || '';

  const {t} = useTranslation();

  const videoRef = useRef(null);
  const [isRenderComponent, setIsRenderComponent] = useState(false);

  const tryItOut = () => {
    setIsRenderComponent(true);
  };

  useEffect(() => {
    if (videoPath) {
      videoRef.current.load();
      videoRef.current.play();
    }
  }, [videoPath]);

  return (
    <div style={{backgroundColor: backgroundColor}}>
      <div className={'xl:px-24 mx-auto lg:py-24 py-12  px-5'}>
        <Row>
          <Col md={12} xs={24}
               className={'text-center' +
               (isMediaLeft ? ' order-first' : ' order-last mt-6')}>
            {
              pathImage ?
                <img src={pathImage} className={styles.imageFeature}/>
                : <video controls className={'lg:w-9/12 mx-auto'}
                         ref={videoRef}>
                  <source src={videoPath}/>
                  Your browser does not support the video tag.
                </video>
            }
          </Col>
          <Col md={12} xs={24}
               className={'flex items-center' +
               (isMediaLeft ? ' mt-6 lg:mt-0' : '')}>
            <div className={'w-95'}>
              <div className={styles.titleFeature}>
                {title}
              </div>
              <div className={styles.description + ' lg:my-12 my-6'}>
                {description}
              </div>
              {!isRenderComponent ? <button className={styles.btnTryIt +
                (isMediaLeft ? '' : ' mb-6 lg:mb-0')} onClick={() => tryItOut()}>
                  {t('Try it out')} >>
                </button> :
                componentRender
              }
            </div>
          </Col>
        </Row>
      </div>

    </div>
  );
}
