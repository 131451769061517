import React from 'react';
import styles from './styles.module.scss';

export default function BlockSection(props) {
  let pathImageBackground = props.pathImageBackground || '';
  let title = props.title || '';
  let description = props.description || '';
  let buttonText = props.buttonText || '';
  const redirectPage = () => {
    props.callbackRedirect && props.callbackRedirect();
  };

  return (
    <div style={{backgroundImage: `url(${pathImageBackground})`}}
         className={'hello ' + styles.section}>
      <div className={styles.content}>

        <div className={styles.container + ' xl:ml-24 mx-5 '}>
          <div className={styles.title}>
            {title}
          </div>

          <div className={styles.description + ' mt-8 mb-8'}>
            {description}
          </div>

          <button className={styles.buttonRedirect}
                  onClick={() => redirectPage()}>{buttonText} >>
          </button>

        </div>
      </div>
    </div>
  );
}
