import React from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';

import MainLayout from '../../layouts/Main';
import BgNLP from '../../assets/imgs/bg_NLP.jfif';
import BgComputerVision from '../../assets/imgs/bg_computer_vision.jfif';
import BgSpeech from '../../assets/imgs/bg_speech.jfif';
import BlockSection from '../../components/BlockSection';
import {objRoute} from '../../routes';
import {CONFIG} from '../../config';

export default function Home() {
  const router = useHistory();
  const {t} = useTranslation();

  return (
    <MainLayout>

      <BlockSection
        title={t('Natural Language Processing')}
        description={t(CONFIG.textIntro)}
        buttonText={t('Try it out')}
        pathImageBackground={BgNLP}
        callbackRedirect={() => router.push(
          objRoute.naturalLanguageProcessing.path)}
      />

      <BlockSection
        title={t('Computer Vision')}
        description={t(CONFIG.textIntro)}
        buttonText={t('Try it out')}
        pathImageBackground={BgComputerVision}
        callbackRedirect={() => router.push(objRoute.computerVision.path)}
      />

      <BlockSection
        title={t('Speech')}
        description={t(CONFIG.textIntro)}
        buttonText={t('Try it out')}
        pathImageBackground={BgSpeech}
        callbackRedirect={() => router.push(objRoute.speech.path)}
      />

    </MainLayout>
  );
}
