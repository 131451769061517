export const CONFIG = {
  apiDomain: process.env.REACT_APP_API_DOMAIN_WEB,
  apiDomainVideo: process.env.REACT_APP_API_DOMAIN_VIDEO,
  languageKey: 'language',

  analyticLanguages: [
    {key: 'ko', text: '한국어'},
    {key: 'en', text: 'English'},
    {key: 'ja', text: '日本語'},
  ],

  translatorLanguages: [
    {key: 'vi', text: 'Tiếng việt'},
    {key: 'ja', text: '日本語'},
    {key: 'zh-Hans', text: '简体中文'},
    {key: 'ko', text: '한국어'},
    {key: 'en', text: 'English'},
  ],
  textToSpeechLanguages: [
    {key: '(vi-VN, An)', text: 'Tiếng việt'},
    {key: '(zh-CN, HuihuiRUS)', text: '中国人'},
    {key: '(en-US, AriaRUS)', text: 'English (US)'},
    {key: '(ja-JP, Ichiro, Apollo)', text: '日本人・男性'},
    {key: '(ja-JP, HarukaRUS)', text: '日本人・女性'},
    {key: '(ko-KR, HeamiRUS)', text: '한국어'},
  ],
  messageValidForm: {
    required: 'This field is required',
    email: 'This field is not a valid email',
  },
  error: `Some thing went wrong`,
  messageMustSelectFile: 'You must select file',
  textDescriptionFeature: 'Sentiment scores are provided on a 1 point scale. The closer the sentiment score is to 1, indicates positive sentiment. The closer it is to 0, indicates negative sentiment.',
  textIntro: 'NTQ is proud to be a company that holds realistic achievement in developing AI for many sectors, and we always support the clients as a reliable partner. To clients who are not specialized in technical domain, we always provide service following the model “offshore research and development + professional expertise” in order to develop customized AI for clients’ business.',
  wrongCode: 'Wrong code',
  networkOrTimoutError: 'There has been a network or timeout error',
};