import React, {useEffect, useRef, useState} from 'react';
import {Col, Form, Input, Row, Select} from 'antd';
import {CONFIG} from '../../config';
import {NaturalLanguageProcessingService} from '../../services/NaturalLanguageProcessing';
import {SpeechService} from '../../services/Speech';
import {useTranslation} from 'react-i18next';
import {util} from '../../helpers/util';

export default function Translator() {
  const [form] = Form.useForm();

  const [dataTranslate, setDataTranslate] = useState(null);
  const [isDisabledTranslateText, setIsDisabledTranslateText] = useState(false);
  const [isDisabledTextToSpeech, setIsDisabledTextToSpeech] = useState(true);
  const [sourceAudio, setSourceAudio] = useState(null);
  const audioRef = useRef(null);
  const {t} = useTranslation();

  const onFinish = async (values) => {
    setIsDisabledTranslateText(true);
    try {
      let res = await NaturalLanguageProcessingService.translate({
        text: values.text,
        to: values.languageTranslation,
      });
      let result = res.data;
      if (result.length) {
        setDataTranslate({
          translatedText: result[0].translations[0].text,
          language: result[0].detectedLanguage.language,
          score: result[0].detectedLanguage.score,
        });
        form.setFieldsValue({
          translatedText: result[0].translations[0].text,
        });
        setIsDisabledTextToSpeech(false);
      }
      setIsDisabledTranslateText(false);
    } catch (e) {
      util.handleError(e);
    }
  };

  const convertToSpeech = async () => {
    setIsDisabledTextToSpeech(true);
    try {
      let res = await SpeechService.textToSpeech({
        text: form.getFieldValue('translatedText'),
        voice: form.getFieldValue('voiceTranslation'),
      });
      let audioBlob = new Blob([res.data], {type: 'audio/mpeg'});
      let audioURL = URL.createObjectURL(audioBlob);
      if (audioURL.length > 5) {
        setSourceAudio(audioURL);
      }
      setIsDisabledTextToSpeech(false);
    } catch (e) {
      setIsDisabledTextToSpeech(false);
      util.handleError(e);
    }

  };
  useEffect(() => {
    if (sourceAudio) {
      audioRef.current.load();
      audioRef.current.play();
    }
  }, [sourceAudio]);
  return (
    <div>
      <Form
        initialValues={{
          languageTranslation: 'vi',
          voiceTranslation: '(vi-VN, An)',
        }}
        form={form}
        name="form-text-analytics"
        onFinish={onFinish}
      >
        <Row className={'justify-between'}>
          <Col sm={11} md={11} xs={24}>
            <div>
              <div className={'lg:h-12 font-bold font-16px mb-2'}>{t(
                'Enter the text you’d like to translate:')}
              </div>
              <Form.Item
                name={'text'}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: t(CONFIG.messageValidForm.required),
                  }]}
              >
                <Input.TextArea rows={4}/>
              </Form.Item>
            </div>
            <div className={'mt-12 mb-12'}>
              <div className={'font-bold font-16px mb-2'}>{t(
                'Translate to:')}</div>
              <Form.Item
                name={'languageTranslation'}
              >
                <Select>
                  {CONFIG.translatorLanguages.map(item =>
                    <Select.Option value={item.key}
                                   key={item.key}>{item.text}</Select.Option>,
                  )}
                </Select>
              </Form.Item>
            </div>
            <button className={'btn-run'} disabled={isDisabledTranslateText}
            >{t('Translate Text')}
            </button>
            {dataTranslate && <div className={'font-16px mt-6'}>
              <div>
                <strong>{t(
                  'Detected language:')}</strong> {dataTranslate.language ||
              ''}
              </div>
              <div className={'mt-2'}>
                <strong>{t(
                  'Detection confidence:')}</strong> {dataTranslate.score ||
              ''}
              </div>
            </div>
            }
          </Col>
          <Col sm={11} md={11} xs={24} className={'mt-10 lg:mt-0'}>
            <div className=''>
              <div className={'lg:h-12 font-bold font-16px mb-2'}>{t(
                'Translated text:')}
              </div>
              <Form.Item
                name={'translatedText'}
              >
                <Input.TextArea rows={4}
                                disabled={true}
                />
              </Form.Item>
            </div>
            <div className={'mt-12 mb-12'}>
              <div className={'font-bold font-16px mb-2'}>{t(
                'Select voice font:')}
              </div>
              <Form.Item
                name={'voiceTranslation'}
              >
                <Select>
                  {CONFIG.textToSpeechLanguages.map(item =>
                    <Select.Option value={item.key}
                                   key={item.key}>{item.text}</Select.Option>,
                  )}
                </Select>
              </Form.Item>
            </div>
            <button type={'button'} className={'btn-run'}
                    disabled={isDisabledTextToSpeech}
                    onClick={() => convertToSpeech()}>{t(
              'Convert text-to-speech')}
            </button>
            {sourceAudio &&
            <audio controls className={'mt-3 w-11/12'} ref={audioRef}>
              <source src={sourceAudio} type="audio/mpeg"/>
              Your browser does not support the audio element.
            </audio>
            }
          </Col>
        </Row>

      </Form>
    </div>
  );
}
