import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'antd/dist/antd.css';
import './assets/styles/global.scss';
import './assets/styles/override.scss';
import './index.css';

import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import languages from '../src/locales';

i18n.use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: languages,
    lng: 'english',
    fallbackLng: 'english',
    nsSeparator: false,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });
localStorage.removeItem('validatedToken');

ReactDOM.render(
  <App/>,
  document.getElementById('root'),
);


